@import "~bootstrap/scss/bootstrap";
@font-face {
	font-family: "pdfEng";
	src: url('https://www.fujienglish.com/fonts/mplus/MPLUS1p-Regular.ttf');
}
//src: url('https://www.fujienglish.com/fonts/mplus/MPLUS1p-Regular.ttf');
.navbar{
	padding-right:10px;
	padding-left:5px;
}
.navbar-brand{
	padding-left:1rem;
	padding-left:1rem;
}

.btn-block {
	width:100%;
}

.class-link-button{
	font-size:1.5rem;
	margin:20px auto;
}
.inactive-lang{
	display:none !important;
}
.navbar-logo{
	height:30px;
}
.nav-link{
	text-align:center;
}

@media all and (min-width: 480px) {
	.login {
		padding: 60px 0;
	}

	.login form {
		margin: 0 auto;
		max-width: 320px;
	}

}
.login form{
	padding: 30px 10px 10px;
}
.login button {
	margin-top:25px
}
.login-logo {
	display:flex;
	justify-content:center;
	margin-bottom:20px;
	img {
		width:180px;
	}
}
.login-pw{
	margin-top:10px;
}
.wrong-login-notice {
	color: red;
	margin-top: 10px;
}
.success-login-notice {
	color: green;
	margin-top: 10px;
}
.login-back button{
	margin:0 0 10px;
}
.login .form-group{
	margin-top:10px;
}



.report {
	textarea {
		background:transparent;
		border:none;
		resize:none;
		vertical-align:middle;
		text-align:center;
		min-height:100%;
		width:100%;
		padding:0.25rem;
	}
	tr {
		min-height:50px;
		height:100%;
	}
}
.report-category {
	margin-bottom: 50px;
}

.report-category-heading {
	text-align: center;
	border-style: solid;
	border-color: #000;
	border-width: 2px 0;
	width:100%;
	h2 {
		position:relative;
	}
	input {
		height:100%;
	}

}
.report-new-category-button{
	margin: 15px 0;
}
.report-category-heading-input{
	text-align: center;
	background: transparent;
	border:none;
	&:focus {
		& ~ .report-delete-category  {
			display:inline-block;
		}
		& ~ .chrome-picker  {
			display:block;
		}		
	}
}
.chrome-picker {
	position:absolute;
	right:50%;
	display:none;
	&:hover {
		display:block;
	};
	z-index:50;
}

.report-delete-category{
	position:absolute;
	bottom:0;
	display:none;
	margin-left:2px;
	&:hover {
		display:inline-block;
	}
}
.report-language-toggle input{
	display:none;
}
.report-new-subcategory-button{
	margin-left:5px;
}
.report-subcategory-heading, .report-subcategory-level {
	white-space: nowrap;
	width:0.1%;
	vertical-align: middle;
	height:50px;
	min-height:100%;
	padding:0 !important;
	border: 1px solid #dee2e6;
}
.report-subcategory-level {
	div {
		padding: 0.25rem;
		width:55px;
		text-align:center;
		display:inline-grid;
		align-items:center;
		height:100%;
	}
	input {
		padding: 0.25rem;
		width:55px;
		background:transparent;
		border:none;
		text-align:center;
		height:100%;
	}
}
.report-subcategory-heading {
	div {
		display: inline-grid;
		align-items: center;
		justify-items: start;
		height:100%;
		position:relative;
		padding:0 5px;
	}
	input {
          grid-area: 1 / 1 / 2 / 2;
          width: 100%;
          padding: 0.25rem;
          border: none;
		  height:100%;
		  background:transparent;
		  min-width:120px;
		&:focus {
			& ~ .report-delete-subcategory {
				display:inline-block;
			}
		}
	}

	span {
		grid-area: 1 / 1 / 2 / 2;
		visibility: hidden;
		 padding: 0.25rem;
		height:0;
		margin:0;
	}

}

.report-delete-subcategory{
	position:absolute;
	left:0;
	display:none;
	margin-left:100%;
	z-index:10;
	&:hover {
		display:inline-block;
	}
}



th.report-grade{
	font-size: 2em;
	border-top:none;
}
.report-grade{
	text-align:center;
	vertical-align:middle;
	width:9%;
	height:100%;
	min-height:100%;
	border: 1px solid #dee2e6;
	min-width:130px;
	position:relative;
	textarea:focus {
		& ~ .report-delete-grade {
			display:inline-block;
		}
		
	}
	padding:0 !important;
	div {
		padding:5px;
	}
}
.report-grade-wrapper{
	height:100%;
	display:inline-grid;
}
.report-add-grade{
	div {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
	}
	border: 1px solid #dee2e6;
	//height:50px;
}



.report-delete-grade {
	position: absolute;
	white-space: nowrap;
	bottom: 100%;
	margin-bottom: -2px;
	right: 0;
	display:none;
	&:hover {
		display:inline-block;
	}
}

.subcategory-heading > div {
  transform: 
    /* Magic Numbers */
    
    /* 45 is really 360 - 45 */
    rotate(270deg);
  
}
.subcategory-heading > div > span {
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
}






/* CLASSES */
.class-details{
	margin-bottom:-200px;
	z-index:110;
	h1 {
		font-size:2em;
	}
	h3 {
		font-size:1.4em;
	}
	h4 {
		font-size:1.2em;
	}
	h5 {
		font-size:1em;
	}
	h4 input{
		width:200px;
		}
	h5 input{
		width:70px;
		}
}
.class-details-button{
	margin-top:11px;
	button {
		position:relative;
		font-size:0.8em;
	}
}
@media all and (min-width: 1080px) {
	.class-details-button button:hover .tooltip{
		display:block;
	}
}
.course-names{
	white-space:nowrap;
	cursor:pointer;
	position:relative;
}
.records-term {
	white-space:nowrap;
}
th.course-names, th.records-term{
	text-align:center;
	cursor:default;
}
.sticky-name{
	position:sticky;
	left:0;
	span{
		 background:white;
		 padding:5px;
		 border-radius:5px;
	}
	z-index:100;
}
.class-student-row{
	height:40px;
}
.class-row-previous{
	background: #eee;
}
.class-student-row .class-grade-previous{
	padding:0;
	position:relative;
}
.class-input-grade{
	padding:0 !important;
	height:100%;
	width: 40px;
	
	select{
		display:none;
	}
	position:relative;
}
.class-input-grade-select {
	height:100%;
	width:100%;
	border-radius:0;
	background-color:transparent !important;
	display:flex;
	align-items:center;
	justify-content:center;
	position:relative;
	padding:5px;
	font-size:1em;
	&:focus {
		z-index:110;
		box-shadow: 0 0 1px 4px #8bc3ff;
	}
	&:focus + .class-grade-tooltip {
		display:block;
	}
}
.class-input-grade-options{
	position:absolute;
	z-index:1075;
	background:white;
	display:none;
	width:40px;
	left:40px;
	top:50%;
	transform:translateY(-50%);
	border:1px solid #777682;
	border-radius:3px;
	div {
		height:30px;
		padding:5px;
		&:hover{
			background:#777682;
			color:white;
			cursor:pointer;
		}
	}
}
.class-input-grade-options.options-show{
	display:block;
}
.class-input-grade-options.options-show-left{
	display:block;
	left:auto;
	right:40px;
}
.class-input-change{
	position:absolute;
	top:0px;
	right:3px;
	pointer-events: none;
	font-size:0.8em;
}
@media all and (min-width: 1080px) {
	.class-input-grade:hover .class-grade-tooltip{
		display:block;
	}
}
.class-prev-grade{
		height:40px;
}
td.class-student-comment{
	width:90%;
	white-space:nowrap;
	position:relative;
	text-align:left !important;
	span {
		position:absolute;
		left:0;
		padding-left:5px;
		padding-top:5px;
	}
	.class-student-comment-overflow{
		overflow:hidden;
		position:relative;
		height:100%;
	}
}
@media all and (min-width: 1080px) {
	td.class-student-comment:hover .class-student-comment-tooltip{
		display:block;
	}
}
th.class-student-comment{
	text-align:center;
	border-bottom: 1px solid #dee2e6 !important;
}
.class-student-comment-modal{
	resize:none;
	width:100%;
}
.class-student-comment-label{
	width:100%;
	span {
		float:right;
	}
}
.class-student-prev-comment{
	border-top:1px solid #dee2e6;
	div{
		font-size:1.2em;
	}
}
.class-student-button{
	padding: 0 !important;
	display:flex;
	justify-content:center;
	align-items:center;
	white-space:nowrap;
	border:none !important;
	button, a {
		font-size:1em;
	}
}
.class-add-student {
	position: relative;
	height: 40px;
	margin-top: 11px;
	.disabled.list-group-item {
		opacity: 0.6;
	}
	.card {
		z-index:120
	}
	button{
		font-size:0.8em;
	}
}
.student-add-error{
	color:red;
	padding-left:5px;
}
.class-average{
	width:40px;
	position:relative;
}
.class-avg-over{
	position:absolute;
	top:0;
	left:0;
	margin-left:4px;
}
.class-avg-under{
	position:absolute;
	bottom:0;
	right:0;
	margin-right:4px;
}
.class-avg-div{
	border-bottom:1px solid rgb(33, 37, 41);
	display:block;
	transform: rotate(-30deg);
}
.class-empty-grades .class-invalid-grade{
	border:2px solid red !important;
}

.table-header-rotated {
	border-collapse: collapse;
	margin-left:-1px;
	margin-right:-1px;
	font-size:0.8em;
	margin-bottom:150px;
}
.table-header-rotated td {
  vertical-align:middle;
}
 .table-header-rotated th {
  padding: 6px 5px;
  border-top: 0;
}
.table-header-rotated td {
  text-align: center;
  padding: 5px 5px;
  border: 1px solid #ccc;
  height:40px;
  background-clip: padding-box;
}
 .table-header-rotated th.rotate {
  height: 100px;
  white-space: nowrap;
  position:relative;
  top:0;
  z-index:10;
  transition:top 0.1s;
}
 .table-header-rotated th.rotate > div {
  -webkit-transform: translateY(-3px) rotate(315deg);
      -ms-transform: translateY(-3px) rotate(315deg);
          transform: translateY(-3px) rotate(315deg);
  width: 30px;
}
 .table-header-rotated th.rotate > div > span {
	border-radius:5px;
  padding: 5px 10px;
}
.table-header-rotated th.row-header {
  padding: 0 10px;
  border-bottom: 1px solid #ccc;
}

td.table-borderless{
	border:none;
	padding:0;
}
.class-x-all {
	button {
		border-radius:50%;
		height:28px;
		width:28px;
		display:flex;
		justify-content:center;
		align-items:center;
		font-size:1em;
	}
}

.course-names.course-all-avg-select{
	padding:0 5px;
	select {
		text-align:center;
		font-size:1em;
		padding:0.375rem 0;
		width:auto;
	}
}
.grade-comment-info {
	position:absolute;
	top:11px;
	right:-8px;
	font-size:0.8em;
	font-family:serif;
	cursor:pointer;
	color:#fff;
	background-color:rgb(13, 110, 253);
	border-radius:50%;
	height:15px;
	width:15px;
	line-height:15px;
	z-index:90;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-align:center;
}
.course-names-inner{
	padding: 10px 5px;
	width:100%;
	height:100%;
}
.course-names.no-padding{
	padding:0;
}
.class-grade-tooltip{
	opacity:1;
	top:50%;
	right:100%;
	transform:translateY(-50%);
	display:none;
	.arrow {
		top:50%;
		transform:translateY(-50%);
	}
	.tooltip-inner{
		min-width:80px;
	}
}
.class-grade-tooltip.tooltip:hover{
	display:none;
}
.class-report-preview-header{
	padding:0.5rem;
	.modal-title{
		text-align:center;
		width:100%;
	}
}
.class-report-preview-body{
	padding:0;
}
.class-report-preview-group{
	border-top:1px solid #000;
}
.class-report-preview-cat{
	font-weight:bold;
	padding: 0 3px;
}
.class-report-preview-grade{
	padding: 0 3px 1px;
	width:50%;
}
.class-comment-tooltip{
	position:absolute;
	top:50%;
	opacity:1;
	left:100%;
	transform: translateY(-50%);
	display:none;
	.underline {
		border-bottom:1px solid #fff;
		margin: 5px 0;
	}
	.arrow {
		top:50%;
		transform:translateY(-50%);
	}
	&:hover{
		display:none !important;
	}
	.tooltip-inner{
		width:300px;
		max-width:300px;
	}
}
.class-student-comment-tooltip{
	position:absolute;
	top:50%;
	opacity:1;
	transform: translate(-100%, -50%);
	display:none;
	.underline {
		border-bottom:1px solid #fff;
		margin: 5px 0;
	}
	.arrow {
		top:50%;
		transform:translateY(-50%);
	}
	&:hover{
		display:none !important;
	}
	.tooltip-inner{
		width:300px;
		max-width:300px;
	}
}
.class-xall-button{
	position:relative;
}
@media all and (min-width: 1080px) {
	.class-xall-button:hover .class-xall-tooltip{
		display:block;
	}
}
.class-xall-tooltip{
	opacity:1;
	top:50%;
	transform:translate(28px, -50%);
	left:0;
	display:none;
	.arrow {
		top:50%;
		transform:translateY(-50%);
	}
	.tooltip-inner{
		min-width:80px;
	}
}
.class-xall-tooltip.tooltip:hover{
	display:none;
}

/* CLASS LISTS */
.classlist-title{
	text-align:center;
	width:100%;
	padding-top:10px;
	h1 {
		font-size:2em;
	}
}
.classlist{
	.list-group{
		width:100%;
		justify-content:center;
		flex-wrap:wrap;
		max-width:1350px;
	}
}


.classlist-list-item {
	display: flex;
	flex-direction:column;
	font-size: 1em;
	max-width: 208px;
	border: 1px solid rgba(0,0,0,0.125) !important;
	margin: 5px;
	border-radius: 0.25rem !important;
	box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.125);
	padding: 7px 20px;
	span {
		color:#797979;
	}
}
.classlist-class-name{
	font-weight:bold;
	text-align:center;
}
.classlist-class-bottom{
	display:flex;
}
.classlist-class-unit{
	flex:1;
	text-align:right;
	padding-left:10px;
}
.class-unit-blue {
	background-color:#C9DAF8;
}
.class-unit-purple {
	background-color:#D9D2E9;
}
.class-unit-yellow {
	background-color:#FFE599;
}
.class-unit-green {
	background-color:#B6D7A8;
}
.class-unit-red {
	background-color:#F4CCCC;
}
.classlist-new-card {
	padding:5px;
	margin-top:30px;
	background-color:rgb(248, 249, 250);
	.justify-content-end {
		margin-bottom:5px;

	}
}
.studentlist-new-card {
	margin-bottom:30px;
	padding:5px;
	background-color:rgb(248, 249, 250);
	.justify-content-end {
		margin-bottom:5px;

	}
}

.success-create-notice {
	color: green;
	font-size: 1.2em;
	text-align:center;
}


.adminlist-list-item{
	
	button {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
	}
}

.class-delete-row{
	margin: 50px 0 20px;
}

.class-delete-input{
		width:200px;
		margin: 0 20px 20px;
}
/* Archives */
.archive-year{
	text-align:center;
	font-size:2rem;
}
.archive-term{
	text-align:center;
}

/*Studentlist */
.studentlist{
	.list-group{
		width:100%;
		justify-content:center;
		align-items:center;
	}
}
.studentlist-list-item{
	font-size: 1.2em;
	max-width:400px;
}


/* Students */
.student-details {
	padding: 10px;
	margin-bottom:-120px;
	z-index:110;
	position:relative;
}
.records-button{
	margin-top:15px;
}

.new-note-footer{
	margin-top:15px;
	display:flex;
	justify-content:space-between;
}
.new-note-date{
	width:100px;
}
.create-note-button button{
	margin:15px;
}
.student-note{
	display: flex;
	flex-direction:column;
	width: 100%;
	border:1px solid #dee2e6;
	border-radius:5px;
	margin-top:15px;
	position:relative;
	&:hover .student-note-delete {
		display:block;
	}
}
.student-note-text-wrapper {
	display: flex;
	width:100%;
	border-bottom:1px solid #dee2e6;
	
}
.student-note-text{
	width:50%;
	padding:5px;
	white-space: pre-line;
	&:first-of-type{
		border-right:1px solid #dee2e6;
	}
	position:relative;
}
.student-note-footer{
	display: flex;
	width:100%;
	padding:5px;
	align-items:center;
	height:40px;
}
.student-note-date {
	width:100px;
	font-weight:bold;
}
.student-note-delete {
	margin-left:auto;
	display:none;
	button:first-of-type{
		margin-right:35px;
	}
}
.student-external-label{
	width:90px;
	margin-bottom:0;
}
.student-external-input{
	flex:1;
}
.student-external-mid{
	margin: 10px 0
}
.student-external-delete{
	position:absolute;
	top:0;
	right:0;
}
.student-external-info {
	padding:5px;
}
.student-external-link{
	white-space: nowrap;
	overflow:hidden;
	a {
		max-width:200px;
	}
}
.borderless{
	border:none;
}
.records-term.student-class-button{
	padding:0 5px;
	a {
		font-size:1em;
	}
}
.table-borderless button{
	font-size:1em;
	}
.student-record-chart{
	max-width:1300px;
	margin: 0 auto;
}
.chart-icon{
	width:15px;
}
.records-exclude-avg{
	display:flex;
	.input-group {
		width:auto;
		border: 1px solid #6c757d;
		border-radius: 0.25rem;
	}
	span {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		height:100%;
	}
}
.records-exclude-avg-label{
	padding:0.375rem 0.75rem;
	color:#6c757d;
	background-color:white;
	border-radius:0.25rem;
}
.record-grade{
	display:flex;
	justify-content:center;
	align-items:center;
	width:100%;
	height:100%;
}
.student-header {
	display:flex;
	justify-content:center;
	flex-wrap:wrap;
	img {
		max-height:200px;
	}
	input {
		width:100%;
		font-size:2rem;
	}
	label {
		display:block;
		position:relative;
		&:hover{
			.student-photo-delete {
				display:block;
			}
		}
	}
}

@media all and (min-width: 600px) {
	.student-header input {
		font-size:2.5rem;
	}
}
#student_photo {
	opacity:0;
	top:0;
	left:0;
	width:100%;
	height:100%;
	display:block;
	position:absolute;
	cursor:pointer;
}
.student-photo-placeholder{
	border:2px dashed #000;
	display:flex;
	height:200px;
	width:150px;
	justify-content:center;
	align-items:center;
}
.student-photo-delete{
	position:absolute;
	left:0;
	bottom:0;
	display:none;
}

/*p404*/
.p404 {
	text-align:center;
}


.amp-signout{
		background: transparent;
		font: inherit;
		color: rgb(33, 37, 41);
		font-size: 1em;
		padding: 5px;
		min-width: 50px;
}

/* Home page */
.home-motd {
	margin: 30px 3%;
	border-width: 1px 0 1px 0;
	border-style: solid;
	border-color: #d5d7d9;
	white-space: pre-line;
	word-break: break-word;
	h4 {
		margin: 20px;
		font-size:1.3rem;
	}
}
.home-countdown{
	margin-bottom:60px;

	text-align:center;
	h1{
		font-size:2em;
	}
}
.home-countdown-timer{
	display:flex;
	justify-content:center;
}
.home-countdown-block{
	
	padding:3%;
	background-color:#e9ecef;
}
.home-countdown-digit{
	font-size:calc(1.6em + 1.6vw);
}
.home-countdown-unit{
	font-size:calc(0.5em + 0.7vw);
}
.home-jumbo{
	margin:20px;
	background-color: #e9ecef;
	padding: 20px;
	border-radius: 10px;
	&:hover{
		background-color: #d5d7d9;
		cursor:pointer;
	}
}

/* admin page */
.admin-row {
	padding: 50px 0;
	border-width: 1px 0 0 0;
	border-style: solid;
	border-color: #d5d7d9;
	h3 {
		width:100%;
	}
}
.admin-due-text{
	margin-right:10px;
}
.admin-button-pos{
	display:flex;
	align-items:center;
	margin-left:10px;
}
.admin-translate-word{
	padding:5px;
	background-color:#d5d7d9;
	border-radius:5px;
	margin: 5px;
}

.admin-tooltip-cat{
	display:flex;
	flex-wrap:wrap;
	justify-content:center;
}
.admin-tooltip-subcat{
	width:300px;
	padding:15px;
	display:flex;
	align-items:center;
	flex-direction:column;
	border:1px solid #000;
	text-align:center;
}
.admin-tooltip-grade{
	display:flex;
	padding: 5px 0;
}
.admin-tooltip-grade-level{
	width:20px;
	display:flex;
	justify-content:center;
	align-items:center;
	background-color:#d2d2d2;
	border-radius:0px;
}


.modal-abs + .modal {
	position: absolute;
}



